
import { mapActions, mapGetters, mapMutations } from "vuex";
import offers from "@/apollo/queries/offer/offers";

export default {
	props: {
		samplingCars: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			set: '',
			sampling__cars: [
				{
					title: "Топ-предложения",
					slug: 'best'
				},
				{
					title: "Свежие",
					slug: 'fresh'
				},
				{
					title: "До 500 000 ₽",
					slug: 'before-500'
				},
				// {
				// 	title: "Топ-выгода",
				// 	slug: 'test',
				// },
				{
					title: "7-местные",
					slug: 'seats-7'
				},
				{
					title: "До 2 владельцев",
					slug: 'owners-2'
				},
				{
					title: "До 5 лет",
					slug: 'young'
				},
				{
					title: "Седаны",
					slug: 'sedan'
				},
				{
					title: "Премиум",
					slug: 'premium'
				}

			],
			showButtonCompilation: true,
      nextPage: 2,
      firstInfinite: true,
		}
	},
	computed: {
		...mapGetters({
			offers: 'catalog/catalog-cars/offers',
			loading: 'catalog/catalog-cars/loading'
		}),
		offers_list() {
			return this.offers.data || this.offers
		}
	},
	methods: {
		...mapActions({
			request: 'filters/filters/request',
			openModal: 'modal/modal-main/openModal',
		}),
		...mapMutations({
			setOffers: 'catalog/catalog-cars/SET_OFFERS',
			setLoading: 'catalog/catalog-cars/SET_LOADING'
		}),
		async getOffers(set) {
			await this.setLoading(true)
			let response = await this.request({
				query: offers,
				variables: {
					page: 1,
					limit: 9,
					set: set,
					dateFormat: 'j F Y года.',
					sort: this.set === 'best' ? 'created_at|desc' : 'price|asc'
				}
			})
			this.setOffers(response.data.offers)
			await this.setLoading(false)
		},
    async getOffersInfinite(set) {
      let response = await this.request({
        query: offers,
        variables: {
          page: this.nextPage,
          limit: 9,
          set: set,
          dateFormat: 'j F Y года.',
          sort: this.set === 'best' ? 'created_at|desc' : 'price|asc'
        }
      })
      if (this.firstInfinite) {
        this.setOffers([...this.offers.data, ...response.data.offers.data]);
        this.firstInfinite = false
      } else {
        this.setOffers([...this.offers, ...response.data.offers.data]);
      }
    },
		tabClick(car) {
			this.set = car.slug
			this.getOffers(car.slug)
			const target_top = this.$refs.title.getBoundingClientRect().top
			if (this.$device.isMobile)
				window.scrollBy({
					top: target_top - 60,
					behavior: 'smooth'
				})
      this.firstInfinite = true
      this.nextPage = 2
		},
		async filterRequest(assignVariables) {
			try {
				await this.setLoading(true)
				let response = await this.request({ query: offers, variables: assignVariables })

				await this.setLoading(false)
				this.setOffers(response.data.offers);

			} catch (error) {
				return this.$nuxt.error({ statusCode: 404, message: '404' })
			}
		},
    async filterRequestInfinite(assignVariables) {
      try {
        let response = await this.request({ query: offers, variables: assignVariables })

        if (this.firstInfinite) {
          this.setOffers([...this.offers.data, ...response.data.offers.data]);
          this.firstInfinite = false
        } else {
          this.setOffers([...this.offers, ...response.data.offers.data]);
        }

      } catch (error) {
        return this.$nuxt.error({ statusCode: 404, message: '404' })
      }
    },
    infinite() {
      if (this.set) {
        this.getOffersInfinite(this.set)
      } else {
        this.filterRequestInfinite({
          url: '/cars',
          page: this.nextPage,
          dateFormat: 'j F Y года.',
          sort: 'year|desc',
          price_from: 0,
          limit: 9
        })
      }
      this.nextPage++
    },
		moreOffers() {
			this.filterRequest({
				url: '/cars',
				page: 1,
				dateFormat: 'j F Y года.',
				// year_from: Number(new Date().getFullYear()-4),
				// year_to: Number(new Date().getFullYear()),
				sort: 'year|desc',
				price_from: 0,
				// limit: this.$device.isMobile == true ? 3 : 9
				limit: 9
			})
		},
		// openMenu() {
		// 	let payload = {
		// 		modal_component: 'modal-filter-mobile',
		// 		modal_data: 'filter-mobile',
		// 		modal_title: 'Фильтр',
		// 		modal_sub_title: ''
		// 	}
		// 	this.openModal(payload);
		// 	this.$router.push('/cars');
		// }
	},
	mounted() {
		this.moreOffers();
    this.firstInfinite = true
	},
}
