
export default {
  props: {
    offers: Array,
    sliderId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isCanNewInfiniteFetch: true,
      swiperInstance: null,
    }
  },
  mounted() {
    this.initSwiper();
    this.createObserver();
  },
  methods: {
    initSwiper() {
      const swiperOptions = {
        modules: [swiper.Navigation, swiper.Autoplay],
        loop: false,
        autoplay: false,
        initialSlide: 0,
        watchSlidesProgress: true,
        slidesPerView: 1.25,
        spaceBetween: 20,
        breakpoints: {
          0: {},
          600: {
            slidesPerView: 2,
            spaceBetween: 24,
          }
        },
        navigation: {
          nextEl: '.catalog__list .swiper-button-next',
          prevEl: '.catalog__list .swiper-button-prev',
        },
      };

      this.swiperInstance = new swiper.default(
        `.swiper.swiper--catalog.swiper--${this.sliderId}`,
        swiperOptions
      );

      this.swiperInstance.on('touchStart', (swiper, event) => {
        if (event.target.nodeName === 'IMG') swiper.allowTouchMove = false;
      });

      this.swiperInstance.on('touchEnd', (swiper, event) => {
        swiper.allowTouchMove = true;
      });

      this.swiperInstance.on('slideNextTransitionEnd', () => {
        this.checkEndOfSlider();
      });
    },
    createObserver() {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            console.log('Пользователь видит линию!');
            this.fetchNewSlides();
          }
        });
      }, {
        root: this.$refs.swiperСatalog,
        threshold: 0.1,
      });

      this.observer.observe(this.$refs.triggerInfinite);
    },
    stopObserving() {
      if (this.observer) {
        this.observer.unobserve(this.$refs.triggerInfinite);
        this.observer.disconnect();
      }
    },
    checkEndOfSlider() {
      const swiper = this.swiperInstance;
      if (swiper.slides.length - swiper.activeIndex <= 4) {
        this.fetchNewSlides();
      }
    },
    async fetchNewSlides() {
      if (this.isCanNewInfiniteFetch) {
        this.isCanNewInfiniteFetch = false;

        await new Promise((resolve) => setTimeout(resolve, 500));

        this.$emit('goToInfinite');

        this.isCanNewInfiniteFetch = true;
      }
    },
  },
  watch: {
    offers(newOffers) {
      if (this.swiperInstance) {
        this.$nextTick(() => {
          this.swiperInstance.update();
        });
      }
    },
  },
  beforeDestroy() {
    this.stopObserving();
    if (this.swiperInstance) {
      this.swiperInstance.destroy();
    }
  },
}
